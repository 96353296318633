import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useLayoutEffect,
} from 'react'
import { Slider as SliderMUI } from '@material-ui/core'
import { InputLabel, Switch } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import JobCardInfo from './JobCard/JobCardInfo'
import JobCardHardSkills from './JobCard/JobCardHardSkills'
import JobCardLanguages from './JobCard/JobCardLanguages'
import JobCardWorkExperience from './JobCard/JobCardWorkExperience'
import JobCardEducation from './JobCard/JobCardEducation'
import JobCardCertification from './JobCard/JobCardCertification'
import JobCardSoftSkills from './JobCard/JobCardSoftSkills'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import Slider from 'react-slick'
import TabNavItem from './Tab/TabListItem'
import TabContent from './Tab/TabContent'
import { useParams } from 'react-router-dom'
import history from 'history.js'
import CloseIcon from 'app/components/Icons/CloseIcon'
import RatingIcon from 'app/components/Icons/RatingIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function JobCreated() {
    let { offer_guid } = useParams()
    const [activeTab, setActiveTab] = useState('esperienze-professionali')
    const [appState, setAppState] = useState(false)
    // per dialog
    const descriptionElementRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    //per offer
    const [hsList, setHsList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [ssList, setSsList] = useState([])
    const [titleList, setTitleList] = useState([])
    const [certList, setCertList] = useState([])
    const [expList, setExpList] = useState([])
    // const [offerInfo, setOfferInfo] = useState({})
    const [openRating, setOpenRating] = useState(false)
    const [userInfo, setUserInfo] = useState({
        recruiter_guid: '',
        name: '',
        surname: '',
        birth_date: '',
        company_name: '',
        headquarters: '',
        phone: '',
        gender: '',
        email: '',
        size: '',
        description: '',
        sector: [],
        sector_lv4: [],
        sector_lv3: [],
        sector_lv2: [],
        sector_lv1: [],
        created: new Date(),
        lastUpdate: new Date(),
    })

    const initOffer = {
        // recruiter_guid: recruiterguid || '',
        // offer_guid: '',
        // company_name: companyName || '',
        job_name: '',
        sector: [],
        sector_lv4: [],
        sector_lv3: [],
        sector_lv2: [],
        sector_lv1: [],
        location: '',
        driving_license: [],
        job_type: '',
        job_contract: '',
        protected_category: 'false',
        job_percentage: 0,
        isced: '',
        ral: 0,
        ral_type: true,
        photo_url: '',
        description: '',
        hs_weight: 30,
        ss_weight: 5,
        title_weight: 15,
        exp_weight: 50,
        distance_filter: 50,
        start_job_date: '',
        created: new Date(),
        lastUpdate: new Date(),
        lastLocationUpdate: new Date(),
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)

    const {
        recruiter_guid,
        // offer_guid,
        company_name,
        job_name,
        sector,
        sector_lv4,
        sector_lv3,
        sector_lv2,
        sector_lv1,
        location,
        geolocation,
        driving_license,
        job_type,
        job_contract,
        protected_category,
        job_percentage,
        isced,
        ral,
        ral_type,
        photo_url,
        description,
        hs_weight,
        ss_weight,
        title_weight,
        exp_weight,
        distance_filter,
        active,
        created,
        lastUpdate,
        start_job_date,
        lastLocationUpdate,
    } = offerInfo

    const { user } = useAuth()
    const getOfferInfo = async () => {
        try {
            const response = await ApiService.get_offerbyguid(offer_guid) //verificare che offer_guid appartenga al recruiter corretto!
            const body = await response.json()
            console.log(body)

            setOfferInfo({
                ...offerInfo,
                offer_guid: body.offer_guid,
                company_name: body.company_name,
                job_name: body.job_name,
                sector: body.sector,
                location: body.location,
                geolocation: body.geolocation,
                driving_license: body.driving_license,
                job_contract: body.job_contract,
                job_type: body.job_type,
                protected_category: body?.protected_category,
                job_percentage: body?.job_percentage,
                isced: body.isced,
                ral: body.ral,
                ral_type: body?.ral_type || false,
                // photo_url: body.photo_url,
                description: body.description,
                hs_weight: body.hs_weight,
                ss_weight: body.ss_weight,
                title_weight: body.title_weight,
                exp_weight: body.exp_weight,
                active: body?.active,
                start_job_date: body.start_job_date
                    ? new Date(body.start_job_date)
                    : null,
                distance_filter: body.distance_filter,
                created: new Date(body.created),
                lastUpdate: new Date(body.lastUpdate),
                isAvailable: body.isAvailable,
                isFindable: body.isFindable,
                isRandom: body.isRandom,
                lastLocationUpdate: body.lastLocationUpdate,
            })
        } catch (err) {
            console.error(err.message)
            // setOfferInfo(null)
        }
    }

    const getHsList = async () => {
        try {
            const response = await ApiService.get_offershsbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            setHsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getLanguageList = async () => {
        try {
            const response = await ApiService.get_offersLangbyguid(offer_guid)
            const body = await response.json()
            console.log(body)

            setLanguageList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getSsList = async () => {
        try {
            const response = await ApiService.get_offersssbyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setSsList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getTitleList = async () => {
        try {
            const response = await ApiService.get_offerstitlebyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setTitleList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getCertList = async () => {
        try {
            const response = await ApiService.get_offerCertbyguid(offer_guid)
            const body = await response.json()

            console.log(body)
            setCertList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const getExpList = async () => {
        try {
            const response = await ApiService.get_offersexpbyguid(offer_guid)
            const body = await response.json()
            console.log(body)
            setExpList(body)
        } catch (err) {
            console.error(err.message)
        }
    }

    const onSectorSelect = (event, value) => {
        setOfferInfo({
            ...offerInfo,
            sector: value,
        })
    }

    const onChange = (event) => {
        event.preventDefault()
        if (event.target.name === 'isAvailable') {
            setOfferInfo({
                ...offerInfo,
                [event.target.name]: event.target.value,
                isFindable: event.target.value,
            })
        } else {
            setOfferInfo({
                ...offerInfo,
                [event.target.name]: event.target.value,
            })
        }
        console.log(offerInfo)
    }

    const setGeoLocationInfo = (place, geolocation) => {
        setOfferInfo({
            ...offerInfo,
            location: place,
            geolocation: geolocation,
        })
    }

    const onSelect = (event) => {
        event.preventDefault()
        setOfferInfo({
            ...offerInfo,
            [event.target.name]: event.target.value.sort(),
        })
    }

    const handleDateChange = (date) => {
        // se digiti con la tastiera la data non so perchè
        // ti segna il giorno prima rispetto a quello che hai digitato
        // con questo si risolve
        if (date && date.getUTCHours() !== 0) {
            const convertedDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60 * 1000
            )
            date = convertedDate
        }

        setOfferInfo({ ...offerInfo, start_job_date: date })
    }

    const onOpen = () => {
        setOfferInfo({ ...offerInfo, lastUpdate: new Date() })
    }

    const handleSubmit = async () => {
        const res = await ApiService.update_offer(offerInfo)
        if (res.ok) await getOffer()
    }

    const update_lastUpdate = async () => {
        const res = await ApiService.update_offerlastUpdate({
            offer_guid: offer_guid,
            lastUpdate: new Date(),
        })
        // if (res.ok) console.log('Data updated')
    }

    const getOffer = useCallback(() => {
        getOfferInfo()
    }, [userInfo])

    const gethardList = useCallback(async () => {
        await getHsList()
        // await reduceCandidates();
        await update_lastUpdate()
    }, [])

    const getsoftList = useCallback(async () => {
        await getSsList()
        // await reduceCandidates();
        await update_lastUpdate()
    }, [])

    const gettitleList = useCallback(async () => {
        await getTitleList()
        // await reduceCandidates();
        await update_lastUpdate()
    }, [])

    const getcertList = useCallback(async () => {
        await getCertList()
        // await reduceCandidates();
        await update_lastUpdate()
    }, [])

    const getexpList = useCallback(async () => {
        await getExpList()
        // await reduceCandidates();
        await update_lastUpdate()
    }, [])

    const getData = async () => {
        try {
            await getOfferInfo()
            await getHsList()
            await getLanguageList()
            await getSsList()
            await getTitleList()
            await getCertList()
            await getExpList()
        } catch (err) {
            console.error('Error fetching data:', err)
        } finally {
            setAppState(false)
        }
    }

    useEffect(() => {
        if (appState) {
            getData()
        }
    }, [appState])

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    // useEffect(() => {
    //     getData()
    // }, [offer_guid])

    // useEffect(() => {
    //if (appState === true) {
    //getData()
    // await getOfferInfo();
    //setAppState(false)
    // }
    // const interval=setInterval(()=>{
    //   getOrderStatus()
    //  },10000)
    // }, [appState, offer_guid])

    useEffect(() => {
        if (user?.photoURL)
            setOfferInfo({ ...offerInfo, photo_url: user?.photoURL })

        if (user !== null) {
            setUserInfo({
                ...userInfo,
                email: user.email,
                recruiter_guid: user.uid,
                photo_url: user?.photoURL,
            })
            setAppState(true)

            // if (user?.photoURL) setPhotoURL(user.photoURL)
        }
    }, [user])

    const settings = {
        // dots: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const handleClickOpenRating = (scrollType) => () => {
        setOfferInfo({ ...offerInfo, lastUpdate: new Date() })
        setOpenRating(true)
        setScroll(scrollType)
    }

    const handleChangeSlider = (name) => (event, newValue) => {
        setOfferInfo({
            ...offerInfo,
            [name]: newValue,
        })
    }

    const handleCloseRating = () => {
        setOpenRating(false)
    }

    const goback = () => {
        try {
            history.push('/profile-recruiter')
        } catch (e) {
            console.log(e)
        }
    }

    useLayoutEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo({
            top: 0,
            behavior: 'instant', // You can also use 'auto' or 'instant'
        })
    }, [])

    return (
        <>
            <div className="Content-scroll-sticky">
                <div className="offer-page rec">
                    {!appState && offerInfo?.offer_guid === undefined ? (
                        <JobCardInfo
                            offerInfo={offerInfo}
                            onChange={onChange}
                            onSelect={onSelect}
                            handleDateChange={handleDateChange}
                            setGeoLocationInfo={setGeoLocationInfo}
                            onSectorSelect={onSectorSelect}
                            handleSubmit={handleSubmit}
                            getOffer={getOfferInfo}
                            onOpen={onOpen}
                            setOfferInfo={setOfferInfo}
                            offerGuid={offer_guid}
                            loading={appState}
                        />
                    ) : (
                        <>
                            <div className="link2profile">
                                <button onClick={goback}>
                                    {' '}
                                    <KeyboardBackspaceIcon />{' '}
                                </button>
                                <p>Torna al tuo profilo</p>
                            </div>
                            <div className="padding-inline recProfile">
                                <div className="section-title m0">
                                    <div className="d-flex align-center h1">
                                        <h1>{job_name}</h1>
                                        <button
                                            className="button-gear icon-btn"
                                            onClick={handleClickOpenRating(
                                                'paper'
                                            )}
                                        >
                                            {/* <RatingIcon /> */}
                                        </button>
                                    </div>
                                </div>
                                <h3>
                                    Visualizza e modifica i dettagli della tua
                                    offerta.
                                </h3>

                                <JobCardInfo
                                    offerInfo={offerInfo}
                                    onChange={onChange}
                                    onSelect={onSelect}
                                    handleDateChange={handleDateChange}
                                    setGeoLocationInfo={setGeoLocationInfo}
                                    onSectorSelect={onSectorSelect}
                                    handleSubmit={handleSubmit}
                                    getOffer={getOfferInfo}
                                    onOpen={onOpen}
                                    setOfferInfo={setOfferInfo}
                                    loading={appState}
                                />
                            </div>
                            <div className="tab-section">
                                <div className="section-title padding-inline recOffer">
                                    <h3 className="padTop">
                                        Cosa richiede questa posizione?
                                    </h3>
                                    <p className="Pfont">
                                        Seleziona una delle quattro categorie
                                        per aggiungere requisiti.
                                    </p>
                                </div>

                                <ul className="tab-menu d-flex space-between m2">
                                    {appState ? (
                                        <Slider {...settings}>
                                            <div>
                                                <Skeleton
                                                    variant=""
                                                    width="100%"
                                                    height={50}
                                                />
                                            </div>
                                            <div>
                                                <Skeleton
                                                    variant="rect"
                                                    width="100%"
                                                    height={50}
                                                />
                                            </div>
                                            <div>
                                                <Skeleton
                                                    variant="rect"
                                                    width="100%"
                                                    height={50}
                                                />
                                            </div>
                                            <div>
                                                <Skeleton
                                                    variant="rect"
                                                    width="100%"
                                                    height={50}
                                                />
                                            </div>
                                        </Slider>
                                    ) : (
                                        <Slider {...settings}>
                                            {/* <TabNavItem title="Descrizione Lavoro" id="descrizione-lavoro" activeTab={activeTab} setActiveTab={setActiveTab}/> */}
                                            <TabNavItem
                                                title="ESPERIENZE LAVORATIVE"
                                                id="esperienze-professionali"
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                            />

                                            <TabNavItem
                                                title="ISTRUZIONE E FORMAZIONE"
                                                id="istruzione-formazione"
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                            />
                                            <TabNavItem
                                                title="COMPETENZE TRASVERSALI"
                                                id="competenze-trasversali"
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                            />
                                            <TabNavItem
                                                title="COMPETENZE PROFESSIONALI"
                                                id="competenze-tecniche"
                                                activeTab={activeTab}
                                                setActiveTab={setActiveTab}
                                            />
                                        </Slider>
                                    )}
                                </ul>

                                {appState ? (
                                    <ul className="tab-menu-Mobile">
                                        <div>
                                            <Skeleton
                                                variant="rect"
                                                width="100%"
                                                height={40}
                                            />
                                        </div>
                                        <div>
                                            <Skeleton
                                                variant="rect"
                                                width="100%"
                                                height={40}
                                            />
                                        </div>
                                        <div>
                                            <Skeleton
                                                variant="rect"
                                                width="100%"
                                                height={40}
                                            />
                                        </div>
                                        <div>
                                            <Skeleton
                                                variant="rect"
                                                width="100%"
                                                height={40}
                                            />
                                        </div>
                                    </ul>
                                ) : (
                                    <ul className="tab-menu-Mobile">
                                        <TabNavItem
                                            title="ESPERIENZE PROFESSIONALI"
                                            id="esperienze-professionali"
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                        />
                                        <TabNavItem
                                            title="ISTRUZIONE E FORMAZIONE"
                                            id="istruzione-formazione"
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                        />
                                        <TabNavItem
                                            title="COMPETENZE TRASVERSALI"
                                            id="competenze-trasversali"
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                        />
                                        <TabNavItem
                                            title="COMPETENZE TECNICHE"
                                            id="competenze-tecniche"
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                        />
                                    </ul>
                                )}

                                <div className="content-tab padding-inline">
                                    {/* <TabContent id="descrizione-lavoro" activeTab={activeTab}>
                                                <JobCardInfo
                                                    offerInfo={offerInfo}
                                                    onChange={onChange}
                                                    onSelect={onSelect}
                                                    setGeoLocationInfo={setGeoLocationInfo}
                                                    onSectorSelect={onSectorSelect}
                                                    handleSubmit={handleSubmit}
                                                    getOffer={getOfferInfo}
                                                    onOpen={onOpen}
                                                    setOfferInfo={setOfferInfo}
                                                />
                                            </TabContent> */}
                                    <TabContent
                                        id="competenze-tecniche"
                                        activeTab={activeTab}
                                    >
                                        <JobCardLanguages
                                            offerguid={offer_guid}
                                            langList={languageList}
                                            getLanguageList={getLanguageList}
                                            appState={appState}
                                        />
                                        <JobCardHardSkills
                                            hslist={hsList}
                                            offerguid={offer_guid}
                                            getHsList={gethardList}
                                            appState={appState}
                                        />
                                    </TabContent>
                                    <TabContent
                                        id="competenze-trasversali"
                                        activeTab={activeTab}
                                    >
                                        <JobCardSoftSkills
                                            sslist={ssList}
                                            offerguid={offer_guid}
                                            getSsList={getsoftList}
                                            appState={appState}
                                        />
                                    </TabContent>
                                    <TabContent
                                        id="istruzione-formazione"
                                        activeTab={activeTab}
                                    >
                                        <JobCardEducation
                                            titlelist={titleList}
                                            offerguid={offer_guid}
                                            getTitleList={gettitleList}
                                            appState={appState}
                                        />
                                        <JobCardCertification
                                            certlist={certList}
                                            offerguid={offer_guid}
                                            getCertList={getcertList}
                                            appState={appState}
                                        />
                                    </TabContent>
                                    <TabContent
                                        id="esperienze-professionali"
                                        activeTab={activeTab}
                                    >
                                        <JobCardWorkExperience
                                            offerInfo={offerInfo}
                                            explist={expList}
                                            offerguid={offer_guid}
                                            getExpList={getexpList}
                                            appState={appState}
                                        />
                                    </TabContent>
                                </div>
                                {/* <div className="padding-inline">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="btn-like-mui accent"
                                                    onClick={goback}
                                                >
                                                    Torna al tuo profilo
                                                </Button>
                                            </div> */}
                            </div>
                        </>
                    )}
                </div>

                {/* DIALOG DELL'INGRANAGGIO - VALUTAZIONE */}
                {/* <Dialog
                    open={openRating}
                    onClose={handleCloseRating}
                    fullWidth={true}
                    maxWidth={'sm'}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    className="MUIDialog"
                    id="rating-dialog"
                > */}
                {/* <div className="d-flex space-between dialog-title">
                <DialogTitle>Valutazione</DialogTitle>
                <Button onClick={handleCloseRating} color="primary" className='close-dialog'>
                    <CloseIcon />
                </Button>
            </div>
            <DialogContent dividers={scroll === 'paper'}>
                <div className="dialog-layout">
                    <div>
                        <h3>Competenze tecniche</h3>
                        <div className="no-min">
                            <SliderMUI
                                value={hs_weight}
                                onChange={handleChangeSlider("hs_weight")}
                                defaultValue={25}
                                // getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={5}
                                min={0}
                                max={100}
                            />
                        </div>
                    </div>
                    <div>
                        <h3>Competenze trasversali</h3>
                        <div className="no-min">
                            <SliderMUI
                                value={ss_weight}
                                onChange={handleChangeSlider("ss_weight")}
                                defaultValue={25}
                                // getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={5}
                                min={0}
                                max={100}
                            />
                        </div>
                    </div>
                    <div>
                        <h3>Istruzione</h3>
                        <div className="no-min">
                            <SliderMUI
                                value={title_weight}
                                onChange={handleChangeSlider("title_weight")}
                                defaultValue={25}
                                // getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={5}
                                min={0}
                                max={100}
                            />
                        </div>
                    </div>
                    <div>
                        <h3>Esperienza</h3>
                        <div className="no-min">
                            <SliderMUI
                                value={exp_weight}
                                onChange={handleChangeSlider("exp_weight")}
                                defaultValue={25}
                                // getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={5}
                                min={0}
                                max={100}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent> */}
                {/* <div
                        className="d-flex space-between dialog-title"
                        style={{ height: 'auto' }}
                    >
                        <DialogTitle>Filtri</DialogTitle>
                        <Button
                            onClick={handleCloseRating}
                            color="primary"
                            className="close-dialog"
                        >
                            <CloseIcon />
                        </Button>
                    </div>
                    <DialogContent dividers={scroll === 'paper'}>
                        <div className="dialog-layout">
                            <div>
                                <h3>Distanza in Km</h3>
                                <div>
                                    <SliderMUI
                                        onChange={handleChangeSlider(
                                            'distance_filter'
                                        )}
                                        value={distance_filter}
                                        defaultValue={200}
                                        // getAriaValueText={valuetext}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="on"
                                        step={1}
                                        min={2}
                                        max={200}
                                    />
                                </div>
                                <div
                                    className="ricercatab"
                                    style={{
                                        marginTop: '4rem',
                                        marginBottom: 0,
                                    }}
                                >
                                    <p>
                                        Facci sapere se vuoi essere visto dalle
                                        aziende
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked':
                                                        {
                                                            color: '#FFFFFF',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    'rgba(255, 255, 255, 0.08)',
                                                            },
                                                        },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                        {
                                                            backgroundColor:
                                                                '#F06031',
                                                        },
                                                    '& .MuiSwitch-switchBase': {
                                                        color: '#FFFFFF',
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor:
                                                            '#EEEEEE',
                                                    },
                                                    '& .MuiSwitch-thumb': {
                                                        boxShadow: 'none',
                                                    },
                                                }}
                                            />
                                        }
                                        label="Sono disponibile!"
                                        sx={{
                                            justifyContent: 'space-between',
                                            '.MuiFormControlLabel-label': {
                                                color: '#333333',
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                handleSubmit(e)
                                setOpenRating(false)
                            }}
                            color="primary"
                        >
                            Salva
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </div>
        </>
    )
}

export default JobCreated
