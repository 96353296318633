import React, { useEffect, useState, useRef } from 'react'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
import ComebackIcon from '../Icons/ComebackIcon'
import LikeIcon from '../Icons/LikeIcon'
import DislikeIcon from '../Icons/DislikeIcon'
import ShowMoreIcon from '../Icons/ShowMoreIcon'
import HomeIcon from '../Icons/HomeIcon'
import LocationOn from '../Icons/LocationOn'
import getDistanceBetweenPoints from 'app/functions/ComputeDistance'
import LoadingAnimation from '../Animation/LoadingAnimation'
import SwiperContainer, { SwiperSlide } from '../SwiperCard/SwiperContainer'
import { CompleteFormCandidate } from './CompleteForm/CompleteFormCandidate'
import Typography from '@mui/material/Typography'
import ItsMatch from './ProfileComponents/Match/ItsMach'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import PricingPage from './Pricing/ComingSoonPremPopUp'
//import history from 'history.js'
import { useHistory } from 'react-router-dom'

function JumpRecruiter() {
    const [hideCards, setHideCards] = useState(false)
    const [cands, setCandidates] = useState([])
    const [isPending, setIsPending] = useState(true)
    const [getCards, setGetCards] = useState(false)
    const [guid, setGuid] = useState('')
    const [open, setOpen] = useState(false)
    const [showPricingPage, setShowPricingPage] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [sub, setSub] = useState(null)
    const initOffer = {
        offer_guid: '',
        geolocation: {},
        isAvailable: true,
    }
    const [offerInfo, setOfferInfo] = useState(initOffer)

    const { offer_guid, geolocation, isAvailable } = offerInfo

    const swiperContainerRef = useRef(null)
    const { user } = useAuth()
    const { createMatch, sendLike, getActiveSubscription } = useFirestore()
    const history = useHistory()

    const getOfferGuid = async () => {
        try {
            const res = await ApiService.get_activeofferbyguid(user?.uid)
            if (res.ok) {
                const body = await res.json()
                console.log(body)

                setOfferInfo({
                    ...offerInfo,
                    offer_guid: body?.offer_guid,
                    geolocation: body?.geolocation,
                })
                setGetCards(true)

                const resp = await getActiveSubscription(user.uid)
                setSub(resp)
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const computeCandidate = async () => {
        try {
            const response = await ApiService.find_candidates(offer_guid)
            const body = await response.json()
            console.log(body)

            setCandidates(body)
            setGetCards(false)

            setIsPending(false)
        } catch (err) {
            console.error(err.message)
        }
    }

    // set last direction and decrease current index
    const swiped = async (direction, swiped, swiperInstance) => {
        // console.log("direction ", direction)
        // return
        const res = await ApiService.update_swipe({
            offer_guid: offer_guid,
            candidate_guid: cands[swiped.previousIndex][0],
            direction: direction,
        })

        if (res.ok) {
            const body = await res.json()
            if (body?.entity?.match && body?.entity?.allow) {
                // console.log("Candidato Matchato: ", body)
                createMatch(
                    offer_guid,
                    cands[swiped.previousIndex][0],
                    cands[swiped.previousIndex][0]
                )
                // fa comparire dialog con animazione
                setHideCards(true)
                // setTimeout(() => {
                //     setHideCards(false)
                // }, 6000)
            } else if (direction && body?.entity?.allow) {
                sendLike(
                    offer_guid,
                    cands[swiped.previousIndex][0],
                    null,
                    cands[swiped.previousIndex][0]
                )
            } else if (!body?.entity?.allow) {
                swiperInstance.tinder.reset()
                setShowPricingPage(true)
            }
        }

        let feedback = parseInt(localStorage.getItem('feedback'), 10) || 0
        feedback += 1
        if (feedback === 5 || feedback === 15) {
            setOpenPopup(true)
        }
        localStorage.setItem('feedback', feedback)
    }

    const handleSwiping = (direction) => {
        if (swiperContainerRef.current?.swiperInstance) {
            if (direction)
                swiperContainerRef.current.swiperInstance.tinder.yes()
            else swiperContainerRef.current.swiperInstance.tinder.no()
        }
    }

    const handleBackClick = () => {
        if (!sub || sub.status !== 'active') {
            setShowPricingPage(true)
        }
    }

    const disallowSwipe = () => {
        try {
            if (!sub || sub.status !== 'active') {
                setShowPricingPage(true)
                return false
            } else {
                return true
            }
        } catch (err) {
            console.error(err.message)
            return false
        }
    }

    useEffect(() => {
        if (getCards) computeCandidate()
        // setBackOpen(false)
        // setIsPending(false)
    }, [getCards])

    useEffect(() => {
        if (user?.uid) getOfferGuid()
    }, [user])

    return (
        <div className={hideCards ? 'AnimationContainer' : 'container jump'}>
            {showPricingPage && (
                <PricingPage
                    open={showPricingPage}
                    setOpen={setShowPricingPage}
                />
            )}
            {!hideCards && (
                <div className="Content-scroll-sticky">
                    <main className="root-swipe">
                        <div
                            className="app-swipe d-flex flex-column justify-center align-center"
                            id="jumpRec"
                        >
                            <div className="p-relative p-up-down d-flex flex-column justify-center align-center">
                                {isPending ? (
                                    // <div className='loading'>
                                    //     <span className="loader"></span>
                                    // </div>
                                    <LoadingAnimation />
                                ) : cands && cands?.length > 0 ? (
                                    <SwiperContainer
                                        ref={swiperContainerRef}
                                        onTinderSwipe={swiped}
                                        handleBack={() => disallowSwipe()}
                                    >
                                        {cands
                                            .sort((a, b) =>
                                                a[1] <= b[1] ? 1 : -1
                                            )
                                            .map((character, index) => {
                                                return (
                                                    <SwiperSlide
                                                        key={index}
                                                        style={{
                                                            zIndex: 999,
                                                            overflow: 'hidden',
                                                        }}
                                                        // onTouchStart={(e) => {
                                                        //     e.preventDefault()
                                                        //     setGuid(character[0])
                                                        //     setOpen(true)
                                                        // }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setGuid(
                                                                character[0]
                                                            )
                                                            setOpen(true)
                                                        }}
                                                    >
                                                        <article
                                                            className={
                                                                hideCards
                                                                    ? 'job-card-ad job-card-jump grabbable prevent-select hide'
                                                                    : 'job-card-ad job-card-jump grabbable prevent-select'
                                                            }
                                                        >
                                                            <div className="swiper-tinder-label swiper-tinder-label-yes">
                                                                <Typography
                                                                    variant="h4"
                                                                    component="span"
                                                                    style={{
                                                                        color: '#ea6c3a',
                                                                        fontWeight:
                                                                            'bold',
                                                                        textShadow: `
                                                                        -1px -1px 0px #fff,  
                                                                        1px -1px 0px #fff,  
                                                                        -1px 1px 0px #fff,  
                                                                        1px 1px 0px #fff`,
                                                                        display:
                                                                            'inline-block',
                                                                        padding:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    MI PIACE
                                                                </Typography>
                                                            </div>
                                                            <div className="swiper-tinder-label swiper-tinder-label-no">
                                                                <Typography
                                                                    variant="h4"
                                                                    component="span"
                                                                    style={{
                                                                        color: '#8773fc',
                                                                        fontWeight:
                                                                            'bold',
                                                                        textShadow: `
                                                                        -1px -1px 0px #fff,  
                                                                        1px -1px 0px #fff, 
                                                                        -1px 1px 0px #fff,  
                                                                        1px 1px 0px #fff`,
                                                                        display:
                                                                            'inline-block',
                                                                        padding:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    NON FA PER
                                                                    ME
                                                                </Typography>
                                                            </div>

                                                            <div className="d-flex align-center">
                                                                <img
                                                                    src={
                                                                        character[7] !==
                                                                            null &&
                                                                        character[7] !==
                                                                            '' &&
                                                                        character[7] !==
                                                                            'None'
                                                                            ? character[7]
                                                                            : '/images/prof.png'
                                                                    }
                                                                    alt="Immagine profilo"
                                                                />

                                                                {/* <h3 className='ml1 text-ellipsis-1'> {character[2] + " " + character[3]}</h3> */}
                                                                <h3 className="ml1 text-ellipsis-1">
                                                                    {' '}
                                                                    {
                                                                        character[2]
                                                                    }
                                                                </h3>
                                                            </div>

                                                            <h2 className="text-ellipsis-3">
                                                                {character[4]}
                                                            </h2>

                                                            <div className="">
                                                                <p
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <HomeIcon
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    />{' '}
                                                                    {
                                                                        character[5]
                                                                    }
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <LocationOn
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    />{' '}
                                                                    {getDistanceBetweenPoints(
                                                                        geolocation?.lat,
                                                                        geolocation?.lon,
                                                                        character[8],
                                                                        character[9]
                                                                    ) > 2
                                                                        ? getDistanceBetweenPoints(
                                                                              geolocation?.lat,
                                                                              geolocation?.lon,
                                                                              character[8],
                                                                              character[9]
                                                                          )
                                                                        : '< 5'}{' '}
                                                                    km
                                                                </p>
                                                                {/* <p>RAL: {character[6]} €</p> */}
                                                            </div>
                                                            <button
                                                                // onTouchStart={(
                                                                //     e
                                                                // ) => {
                                                                //     e.preventDefault()
                                                                //     console.log(
                                                                //         'clicked'
                                                                //     )
                                                                //     setGuid(
                                                                //         character[0]
                                                                //     )
                                                                //     setOpen(true)
                                                                // }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault()
                                                                    console.log(
                                                                        'clicked'
                                                                    )
                                                                    setGuid(
                                                                        character[0]
                                                                    )
                                                                    setOpen(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                {' '}
                                                                PROFILO COMPLETO
                                                                &nbsp;
                                                                <ShowMoreIcon />
                                                            </button>

                                                            <div className="frame-shape"></div>
                                                        </article>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        <SwiperSlide
                                            style={{
                                                zIndex: 999,
                                                boxShadow:
                                                    '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '3.125rem',
                                            }}
                                        >
                                            <div
                                                className={
                                                    'swiper-tinder no-cards'
                                                }
                                            >
                                                <div
                                                    className="card-container"
                                                    id="placeholdercards"
                                                >
                                                    <div className="bg-el"></div>
                                                    <SearchOutlinedIcon
                                                        style={{
                                                            width: '2em',
                                                            height: '2em',
                                                        }}
                                                    />
                                                    <h3
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        {' '}
                                                        OPS!
                                                        <span>
                                                            Sembra che non ci
                                                            siano più candidati
                                                        </span>
                                                    </h3>
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        Modifica i filtri,
                                                        attiva la modalità
                                                        Kasuale o torna più
                                                        tardi.
                                                    </p>
                                                    <button
                                                        className="buttonJump"
                                                        onClick={() =>
                                                            history.push(
                                                                '/settings-recruiter'
                                                            )
                                                        }
                                                    >
                                                        Vai alle impostazioni
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <div className="actions d-flex space-between align-center">
                                            <div className="icons-jump swiper-tinder-button-no">
                                                <button className="swiper-tinder-button">
                                                    <DislikeIcon />
                                                </button>
                                                <p>Non fa per me</p>
                                            </div>
                                            <div
                                                className="icons-jump swiper-tinder-button-undo"
                                                style={{
                                                    position: 'relative',
                                                    left: '-0.4rem',
                                                }}
                                            >
                                                <button
                                                    className="swiper-tinder-button"
                                                    onClick={handleBackClick}
                                                >
                                                    <ComebackIcon />
                                                </button>
                                                <p>Indietro</p>
                                            </div>
                                            <div className="icons-jump swiper-tinder-button-yes">
                                                <button className="swiper-tinder-button">
                                                    {/* <i className="icon-like"></i> */}
                                                    <LikeIcon />
                                                </button>
                                                <p>Applica</p>
                                            </div>
                                        </div>
                                    </SwiperContainer>
                                ) : (
                                    <SwiperSlide
                                        style={{
                                            zIndex: 999,
                                            boxShadow:
                                                '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '3.125rem',
                                            width: '80%',
                                            margin: 'auto',
                                            top: '0rem',
                                        }}
                                    >
                                        <div
                                            className={'swiper-tinder no-cards'}
                                        >
                                            <div
                                                className="card-container"
                                                id="placeholdercards"
                                            >
                                                <div className="bg-el"></div>
                                                <SearchOutlinedIcon
                                                    style={{
                                                        width: '2em',
                                                        height: '2em',
                                                    }}
                                                />
                                                {isAvailable ? (
                                                    <>
                                                        <h3
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            {' '}
                                                            OPS!
                                                            <span>
                                                                Sembra che non
                                                                ci siano più
                                                                candidati
                                                            </span>
                                                        </h3>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Modifica i filtri,
                                                            attiva la modalità
                                                            Kasuale o torna più
                                                            tardi.
                                                        </p>{' '}
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            {' '}
                                                            OPS!
                                                            <span>
                                                                Sembra che la
                                                                tua offerta non
                                                                sia attiva
                                                            </span>
                                                        </h3>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Attivala per vedere
                                                            nuovi candidati
                                                        </p>{' '}
                                                    </>
                                                )}
                                                <button
                                                    className="buttonJump"
                                                    onClick={() =>
                                                        history.push(
                                                            '/settings-recruiter'
                                                        )
                                                    }
                                                >
                                                    Vai alle impostazioni
                                                </button>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </div>
                        </div>
                        <CompleteFormCandidate
                            open={open}
                            setOpen={setOpen}
                            guid={guid}
                            actions={true}
                            swiped={handleSwiping}
                        />
                    </main>
                </div>
            )}
            <div>
                <Dialog
                    open={openPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="confirm-dialog small-dialog"
                >
                    <Button
                        onClick={() => setOpenPopup(false)}
                        color="primary"
                        className="close-dialog"
                        id="swipCloseBtn"
                    >
                        <Closebtnblue />
                    </Button>
                    <DialogTitle className="Dicci">
                        {'Dicci la tua!'}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Dacci il tuo parere per migliorare la piattaforma.
                            Valuta la tua esperienza cliccando il pulsante qui
                            sotto. Grazie!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenPopup(false)
                                window.location.href =
                                    'https://hylkxrsnso4.typeform.com/to/PFyXdexF'
                            }}
                            color="primary"
                            autoFocus
                        >
                            Prosegui
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {hideCards && (
                <ItsMatch
                    imageSrc={
                        cands &&
                        cands.length > 0 &&
                        cands[0][7] !== null &&
                        cands[0][7] !== '' &&
                        cands[0][7] !== 'None'
                            ? cands[0][7]
                            : '/images/prof.png'
                    }
                    setOpen={setHideCards}
                />
            )}
        </div>
    )
}

export default JumpRecruiter
