import {
    FormControl,
    InputLabel,
    Select,
    TextField,
    CircularProgress,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import itLocale from 'date-fns/locale/it'
import ApiService from 'app/ApiService'
import AddIcon from 'app/components/Icons/AddIcon'
import CloseIcon from 'app/components/Icons/CloseIcon'
import EditIcon from 'app/components/Icons/EditIcon'
import BinIcon from 'app/components/Icons/BinIcon'
import Skeleton from '@material-ui/lab/Skeleton'
import EditFieldDialog from './FooterProfile/EditField'

const certification_table = [
    'Certificazione',
    'Attestato di frequenza',
    'Corso di formazione',
    'Portfolio',
    'Abilitazione professionale',
    'Pubblicazione',
    'Altro',
]

function Certification({ titlelist, candidateguid, getCertList, loading }) {
    const [open, setOpen] = useState(false)
    const descriptionElementRef = useRef(null)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [dialogText, setDialogText] = useState('Aggiungi')
    const [loadingButton, setLoadingButton] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [titleList, setTitleList] = useState([])
    const [titleObjects, setTitleObjects] = useState([])
    const [isEdited, setIsEdited] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)

    const initUserInfo = {
        candidate_guid: candidateguid || '',
        cert_type: '',
        date_end: '',
        description: '',
        cert_title: '',
        created: new Date(),
        lastUpdate: new Date(),
    }

    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        candidate_guid,
        cert_type,
        description,
        date_end,
        cert_title,
        created,
        lastUpdate,
    } = userInfo

    const handleClickOpen = () => () => {
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: false,
        }))
        setTitleObjects(newTitleObjects)

        setDialogText('Aggiungi')
        setOpen(true)
        setUserInfo({
            ...initUserInfo,
            created: new Date(),
            lastUpdate: new Date(),
        })
    }

    const handleClickUpdateDialogOpen = (titleInfo, index) => () => {
        setOpen(false)
        toggleOpen(index)
        setDialogText('Aggiorna')
        console.log(titleInfo)
        setUserInfo({
            ...userInfo,
            id: titleInfo.id,
            cert_type: titleInfo.cert_type,
            date_end: new Date(titleInfo.date_end) || '',
            description: titleInfo.description,
            cert_title: titleInfo.cert_title,
            lastUpdate: new Date(),
        })
    }

    const handleClose = (index) => {
        toggleOpen(index)
        setOpen(false)
        setUserInfo(initUserInfo)
        setFormErrors({})
    }

    const handleDateChange = (date) => {
        setUserInfo({ ...userInfo, date_end: date })
    }

    const onChange = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
        console.log(userInfo)
    }

    const handleAddTitle = async () => {
        setLoadingButton(true)
        const res = await ApiService.create_candCert(userInfo)
        if (res.ok) getCertList()
        handleClose()
        setLoadingButton(false)
    }

    const handleDelete = async (event) => {
        event.preventDefault()
        const res = await ApiService.delete_candCert(userInfo.id)
        if (res.ok) getCertList()
        setOpenConfirm(false)
    }

    const handleSubmit = async () => {
        setLoadingButton(true)
        const res = await ApiService.update_candCert(userInfo)
        if (res.ok) getCertList()
        handleClose()
        setLoadingButton(false)
    }

    const handleClickOpenConfirm = (id) => {
        setOpenConfirm(true)
        setUserInfo({
            ...userInfo,
            id: id,
        })
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }
    const handleFieldChange = (event) => {
        setIsEdited(true)
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        })
    }

    const handleDialogClose = () => {
        if (isEdited) {
            setConfirmOpen(true)
        } else {
            handleClose()
        }
    }

    const handleConfirmClose = (confirm) => {
        setConfirmOpen(false)
        if (confirm) {
            handleClose()
        }
    }
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [open])

    const validate = () => {
        const errors = {}
        if (!cert_type) errors.cert_type = 'Questo campo non può essere nullo'
        if (!cert_title) errors.cert_title = 'Questo campo non può essere nullo'
        return errors
    }

    const toggleOpen = (index) => {
        const newTitleObjects = titleObjects.map((item, i) => ({
            ...item,
            open: i === index ? !item.open : false,
        }))
        setTitleObjects(newTitleObjects)
    }

    useEffect(() => {
        if (isSubmit) {
            setFormErrors(validate())
            if (Object.keys(validate()).length === 0)
                if (dialogText === 'Aggiungi') handleAddTitle()
                else handleSubmit()
            setSubmit(false)
        }
    }, [isSubmit, userInfo])

    useEffect(() => {
        setTitleList(titlelist)

        const newTitleObjects = titlelist.map((_, index) => ({
            open: false,
            index: index,
        }))
        setTitleObjects(newTitleObjects)
    }, [titlelist])

    useEffect(() => {
        setUserInfo({ ...userInfo, candidate_guid: candidateguid })
    }, [candidateguid])

    const renderForm = (index) => {
        console.log(index)
        return (
            <div className="dropdown-form">
                <div className="d-flex align-center m1">
                    <h3>
                        Descrivi questa esperienza, ci aiuterà a presentarti
                        meglio.
                    </h3>
                    <button onClick={handleDialogClose} className="close-card">
                        <CloseIcon />
                    </button>
                </div>

                <div className="dialog-layout">
                    <div>
                        <FormControl
                            variant="filled"
                            fullWidth
                            className="select-arr"
                        >
                            <InputLabel
                                required
                                htmlFor="filled-age-native-simple"
                            >
                                Scegli la tipologia
                            </InputLabel>
                            <Select
                                native
                                fullWidth
                                required
                                name="cert_type"
                                value={cert_type}
                                onChange={handleFieldChange}
                                inputProps={{
                                    name: 'cert_type',
                                    className: 'smaller-text',
                                }}
                            >
                                <option hidden aria-label="None" value={null} />
                                {certification_table.map((title) => {
                                    return (
                                        <option key={title} value={title}>
                                            {title}
                                        </option>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <p className="input-error">{formErrors.cert_type}</p>
                    </div>
                    <div className="custom-input-padding">
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={itLocale}
                        >
                            <KeyboardDatePicker
                                views={['year', 'month']}
                                margin="normal"
                                label="Data conseguimento"
                                format="MM/yyyy"
                                value={date_end || null}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                locale="it-IT"
                                autoOk
                                fullWidth
                                disableFuture
                                invalidDateMessage=""
                                maxDateMessage=""
                                minDateMessage=""
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="dialog-layout fullwidth">
                    <div>
                        <TextField
                            label="Titolo"
                            variant="filled"
                            name="cert_title"
                            value={cert_title}
                            onChange={handleFieldChange}
                            inputProps={{ maxLength: 100 }}
                            fullWidth
                            required
                            color="primary"
                            multiline
                            rows={2}
                        />

                        <p className="input-error">{formErrors.cert_title}</p>
                    </div>
                </div>

                <h3>Racconta la tua esperienza</h3>
                <div className="dialog-layout ">
                    <div>
                        <TextField
                            label="Descrizione dell'esperienza (facoltativo)"
                            variant="filled"
                            name="description"
                            value={description}
                            onChange={handleFieldChange}
                            inputProps={{ maxLength: 500 }}
                            fullWidth
                            color="primary"
                            multiline
                            rows={3}
                        />
                        <p
                            className={
                                description?.length < 500
                                    ? 'ml-auto maxLength'
                                    : 'ml-auto maxLength completed'
                            }
                        >
                            <span>{description?.length}</span> / 500 massimo
                        </p>
                    </div>
                </div>

                <Button
                    variant="contained"
                    onClick={(e) => {
                        e.preventDefault()
                        setSubmit(true)
                    }}
                    color="primary"
                    className="btn-like-mui"
                    disabled={loadingButton}
                    style={{ marginTop: '20px', color: '#fff' }}
                >
                    {loadingButton ? (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                left: '5%',
                            }}
                            color="inherit"
                            size={25}
                        />
                    ) : null}
                    {dialogText}
                </Button>
            </div>
        )
    }

    return (
        <>
            <section>
                <div>
                    <div className="section-title">
                        <h2>Certificazioni e Corsi</h2>
                        <button
                            className="icon-btn"
                            hidden={
                                open || titleObjects.some((item) => item.open)
                            }
                            onClick={handleClickOpen()}
                        >
                            <AddIcon />
                        </button>
                    </div>

                    {open && renderForm()}

                    {!loading && titleList.length <= 0 && !open && (
                        <div className="p1">
                            <div className="placeholder-card">
                                <div className="bg-el"></div>
                                <div className="d-flex flex-column justify-center align-center text-center h-100">
                                    <p className="accent-text-color">
                                        Inserisci la tua formazione per avere
                                        più successo
                                    </p>
                                    <button
                                        onClick={handleClickOpen()}
                                        className="primary-text-color"
                                    >
                                        Inizia qui
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="box-preview">
                        {loading ? (
                            <div className="card-preview new-box">
                                <div
                                    className="section-lightGray"
                                    id="skeltonExperience"
                                >
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width="50%"
                                            height="35px"
                                        />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="70%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="90%" />
                                    </div>

                                    <div>
                                        {' '}
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                    <div>
                                        <Skeleton variant="text" width="30%" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            titleList.map((title, index) => (
                                <div
                                    className="card-preview new-box"
                                    key={index}
                                >
                                    {titleObjects[index].open ? (
                                        renderForm(index)
                                    ) : (
                                        <div className="lightgray-studies">
                                            <div className="section-lightGray studies align">
                                                <div className="d-flex actions-btns">
                                                    <button
                                                        onClick={handleClickUpdateDialogOpen(
                                                            title,
                                                            index
                                                        )}
                                                        className="icon-btn"
                                                    >
                                                        <EditIcon />
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            handleClickOpenConfirm(
                                                                title.id
                                                            )
                                                        }
                                                        className="icon-btn"
                                                    >
                                                        <BinIcon />
                                                    </button>
                                                </div>
                                                <h3 className="bold text-ellipsis-2">
                                                    {title.cert_type}
                                                </h3>
                                            </div>
                                            <div>
                                                <div className="studies">
                                                    <div className="studies-left">
                                                        <p className="bold">
                                                            ✍🏻{' '}
                                                            {title.cert_title}
                                                        </p>
                                                    </div>
                                                    <div className="studies-right">
                                                        {title.date_end && (
                                                            <p>
                                                                📅 Data di
                                                                conseguimento:{' '}
                                                                {format(
                                                                    new Date(
                                                                        title.date_end
                                                                    ),
                                                                    'MM/yyyy'
                                                                )}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <p>{title.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>
            {/* DIALOG PER ELIMINARE LA CARD */}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirm-dialog small-dialog"
            >
                <DialogTitle>{'Sicuro?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confermi di voler eliminare questa voce dal tuo profilo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Elimina
                    </Button>
                    <Button
                        onClick={handleCloseConfirm}
                        color="primary"
                        autoFocus
                    >
                        Annulla
                    </Button>
                </DialogActions>
            </Dialog>
            <EditFieldDialog open={confirmOpen} onClose={handleConfirmClose} />
        </>
    )
}

export default Certification
