import React, { useState, useCallback, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    MenuItem,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    Slider,
    TextField,
    Switch,
} from '@material-ui/core'
import ApiService from 'app/ApiService'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useAuth from 'app/hooks/useAuth'
import FooterProfile from './ProfileComponents/FooterProfile/FooterProfile'
import SendIcon from '@material-ui/icons/Send'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import ReplyIcon from '@material-ui/icons/Reply'
import useFirestore from 'app/hooks/useFirestore'
import PricingPage from './Pricing/ComingSoonPremPopUp'
import Skeleton from '@material-ui/lab/Skeleton'
import { debounce } from 'lodash'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tooltip from '@material-ui/core/Tooltip'

// const job_typology = ['In sede', 'Ibrido', 'Remoto']

// const contracts = [
//     'Part time',
//     'Apprendistato',
//     'Tempo determinato',
//     'Tempo indeterminato',
//     'Freelance',
//     'Stage',
// ]

function FilterPageRecruiter() {
    const [activeTab, setActiveTab] = useState('Il mio piano')
    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = useState('paper')
    const [appState, setAppState] = useState(false)
    const [sub, setSub] = useState(null)
    const [duration, setDuration] = useState('1 Mese')
    const [loading, setLoading] = useState(false)
    const [openPricing, setOpenPricing] = useState(false)
    const [sendFilter, setSendFilter] = useState(false)
    const { user } = useAuth()
    const isMobile = useMediaQuery('(max-width:500px)')
    const { getActiveSubscription } = useFirestore()
    const [openTooltip, setOpenTooltip] = useState(false)

    const styles = {
        root: {
            backgroundColor: '#6C6C6C',
            display: isMobile ? 'grid' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '2rem',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            gap: isMobile ? '0' : '1rem',
            textAlign: 'center',
        },
        closeButton: {
            position: 'absolute',
            top: '1rem',
            right: '2rem',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '1.5rem',
            color: '#fff !important',
            cursor: 'pointer',
        },
        header: {
            textAlign: 'center',
            color: '#ffffff',
            marginBottom: isMobile ? '0rem' : '1rem',
            fontWeight: '500',
            position: 'relative',
            top: isMobile ? '0rem' : '0.5rem',
        },
        cardContainer: {
            display: isMobile ? 'grid' : 'flex',
            gridTemplateColumns: isMobile ? '24rem' : undefined,
            justifyContent: 'center',
            gap: isMobile ? '1rem' : '10%',
            marginBottom: isMobile ? '0' : '-2rem',
            width: isMobile ? '100%' : '80%',
            maxHeight: isMobile ? '18rem' : undefined,
            overflowY: isMobile ? 'auto' : 'visible',
            height: isMobile ? 'auto' : undefined,
            width: isMobile ? '25rem' : undefined,
            zIndex: 11111,
        },
        card: {
            width: isMobile ? '240px' : '200px',
            padding: '1.8rem',
            borderRadius: '12px',
            backgroundColor: '#ffffff',
            textAlign: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            height: isMobile ? '16rem' : '100%',
            cursor: 'pointer',
            zIndex: 1002,
        },
        cardPopular: {
            border: '4px solid #ff6b00',
        },
        price: {
            fontSize: isMobile ? '3rem' : '2rem',
            color: '#5058ED',
            fontWeight: 'bold',
            margin: '1rem auto',
        },
        priceBLUE: {
            fontSize: isMobile ? '3rem' : '2rem',
            color: '#5058ED',
            fontWeight: 'bold',
            margin: '1rem auto',
        },
        planText: {
            marginBottom: '0.5rem',
            fontSize: isMobile ? '1rem' : '0.7rem',
            color: '#777',
        },
        button: {
            backgroundColor: '#664cff',
            color: '#ffffff',
            border: 'none',
            borderRadius: '20px',
            padding: '0.2rem 0.5rem',
            fontSize: isMobile ? '1rem ' : '0.7rem',
            cursor: 'pointer',
            position: 'absolute',
            bottom: '-0.7rem',
            visibility: 'visible',
            top: 'auto',
            right: isMobile ? '1%' : undefined,
            left: isMobile ? '1%' : undefined,
            margin: isMobile ? 'auto' : undefined,
            width: isMobile ? 'fit-content' : 'auto',
            zIndex: 1003,
        },
        buttonHidden: {
            visibility: 'hidden',
        },
        buttonHover: {
            backgroundColor: '#593bcc',
        },
        buttonPremium: {
            marginTop: isMobile ? '0rem ' : '2rem',
            backgroundColor: '#fff',
            color: '#ea6c3a',
            border: 'none',
            borderRadius: '20px',
            padding: '0.5rem 1rem',
            fontSize: '1rem',
            cursor: 'pointer',
            fontWeight: '400',
            top: 'auto',
            right: 'auto',
            position: 'relative',
            cursor: 'pointer',
        },
        subtext: {
            marginTop: '1rem',
            color: '#fff',
            fontSize: isMobile ? '1rem' : '0.7rem',
        },
        subtextRecruiter: {
            marginTop: '1rem',
            color: '#fff',
            fontSize: '0.7rem',
            textAlign: 'center',
        },
    }
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }
    const customGridStyles = `
          .slick-slider.slick-initialized {
               display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
        height: 7rem;
        top: 0rem;
        justify-items: end;
        align-items: center;
            
         
            .slick-dots li button:before{
            color:rgb(80, 88, 237)}
            .slick-dots{
          display:block !important ;
    
         
    }}
          .slick-slider .slick-list {
        transform: translateZ(0);
        width:100%;
        display:flex
    }
         @media only screen and (max-width: 500px) {
       .slick-slider.slick-initialized {
         top: -4rem;
       }
     }
    
            `

    const recruiterContent = [
        { icon: <SendIcon />, text: 'Più interessi al giorno' },
        { icon: <RemoveRedEyeIcon />, text: 'Vedi chi si candida' },
        { icon: <ReplyIcon />, text: 'Torna indietro' },
    ]

    // Use isCandidatePage to determine which content to show

    const initUserInfo = {
        recruiter_guid: user?.uid || '',
        distance_filter: 50,
        protected_cat_filter: '',
        created: '',
        lastUpdate: '',
        isFindable: true,
        isRandom: false,
    }
    const [userInfo, setUserInfo] = useState(initUserInfo)
    const {
        recruiter_guid,
        distance_filter,
        job_type,
        job_contract,
        protected_cat_filter,
        created,
        lastUpdate,
        isAvailable,
        isFindable,
        isRandom,
    } = userInfo

    const getUserInfo = async () => {
        const response = await ApiService.get_offerfiltersbyid(recruiter_guid)
        const body = await response.json()
        // console.log(body)

        try {
            setUserInfo({
                ...userInfo,
                distance_filter: body.distance_filter,
                isAvailable: body.isAvailable,
                isFindable: body.isFindable,
                isRandom: body.isRandom,
            })
        } catch (err) {
            console.error(err.message)
        }
    }

    const handleTooltipClose = () => {
        setOpenTooltip(false)
    }

    const handleTooltipOpen = () => {
        setOpenTooltip(true)
    }

    const toggleRandomChecked = () => {
        setUserInfo({
            ...userInfo,
            isRandom: !isRandom,
        })

        setSendFilter(true)
    }

    // const handleClose = async() => {
    //     await getUserInfo()
    //     setOpen(false);
    // };

    // const onSelect = (event) => {
    //     event.preventDefault()
    //     setUserInfo({
    //         ...userInfo,
    //         [event.target.name]: event.target.value
    //     })
    //     // console.log(userInfo)
    // }

    // const handleChangeSlider = (event, newValue) => {
    //     setUserInfo({
    //         ...userInfo,
    //         distance_filter: newValue
    //     })
    //     // console.log(userInfo)
    // };

    // const handleSubmit = async () => {
    //     const res = await ApiService.update_offerfiltersbyid(userInfo);
    //     handleClose();
    // };

    const handleChangeSlider = (event, newValue) => {
        console.log(newValue)
        handleDebouncedChange(newValue)
    }

    const handleDebouncedChange = useCallback(
        debounce(async (newValue) => {
            setUserInfo({
                ...userInfo,
                distance_filter: newValue,
            })
            setSendFilter(true)
        }, 700),
        []
    )

    const handleSubmit = async () => {
        // console.log("invio");
        const res = await ApiService.update_offerfiltersbyid(userInfo)
        //Handle errors here
        // console.log(res);
        // handleClose();
    }

    // async function getData() {
    //     await getUserInfo()
    //     setAppState(false)
    // }

    async function getData() {
        const res = await getActiveSubscription(user.uid)
        setSub(res)
        if (res?.items.length > 0)
            setDuration(res?.items[0]?.price.metadata.duration)
        await getUserInfo()
        setAppState(false)
    }

    useEffect(() => {
        if (user !== null) {
            setUserInfo({
                ...userInfo,
                recruiter_guid: user.uid,
            })
            setAppState(true)
        }
    }, [user])

    useEffect(() => {
        if (appState) getData()
    }, [appState])

    useEffect(() => {
        if (sendFilter) {
            handleSubmit()
            setSendFilter(false)
        }
    }, [sendFilter])

    const TabNavItem = ({ title, id, activeTab, setActiveTab }) => {
        const handleClick = () => {
            setActiveTab(id)
        }

        return (
            <li
                className={`tab-item ${activeTab === id ? 'active' : ''}`}
                onClick={handleClick}
            >
                {title}
            </li>
        )
    }

    // Tab Content Component
    const TabContent = ({ id, activeTab, children }) => {
        return activeTab === id ? (
            <div className="tab-content">{children}</div>
        ) : null
    }

    return (
        <>
            <PricingPage open={openPricing} setOpen={setOpenPricing} />
            <div className="Content-scroll-sticky">
                <div className="filter-scroll">
                    <main className="padding-inline filters">
                        <h1>Impostazioni</h1>
                        <div className="tab-section">
                            <ul className="tab-menu plan d-flex space-between">
                                <>
                                    <TabNavItem
                                        title="Il mio piano"
                                        id="Il mio piano"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        title="Impostazioni di ricerca"
                                        id="Impostazioni di ricerca"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                    <TabNavItem
                                        title="Account e privacy"
                                        id="Account e privacy"
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                </>
                            </ul>

                            <div className="content-tab plan">
                                <TabContent
                                    id="Il mio piano"
                                    activeTab={activeTab}
                                >
                                    {loading ? (
                                        <div className="plan">
                                            <Skeleton
                                                variant="text"
                                                className="planCardheaderblack"
                                                width="80%"
                                                height={40}
                                            />
                                            <Skeleton
                                                variant="text"
                                                className="planCardheaderOrange"
                                                width="60%"
                                                height={40}
                                            />
                                            <div className="PlanCard">
                                                <div className="PlanCardcomponent">
                                                    <Skeleton
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                        height={30}
                                                    />
                                                </div>
                                                <div className="PlanCardcomponent">
                                                    <Skeleton
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                        height={30}
                                                    />
                                                </div>
                                                <div className="PlanCardcomponent">
                                                    <Skeleton
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="80%"
                                                        height={30}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : sub === null ||
                                      sub?.status !== 'active' ? (
                                        <>
                                            <div className="plan">
                                                <h1 className="planCardheaderblack">
                                                    Al momento utilizzi il piano
                                                    standard, gratuito
                                                </h1>
                                                <h1 className="planCardheaderOrange">
                                                    <a
                                                        onClick={() =>
                                                            setOpenPricing(true)
                                                        }
                                                    >
                                                        Passa a Premium.
                                                    </a>
                                                </h1>

                                                <div className="PlanCard">
                                                    <div className="PlanCardcomponent">
                                                        <SendIcon />
                                                        <h3>
                                                            15 interessi al
                                                            giorno
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <RemoveRedEyeIcon />
                                                        <h3>
                                                            Vedi chi si candida
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <ReplyIcon />
                                                        <h3>Torna indietro</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="InterrompiFilter"></div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="plan prentium">
                                                <h2 className="planCardheaderblack">
                                                    Al momento utilizzi il piano
                                                    Premium per la durata di{' '}
                                                    {duration}.
                                                </h2>
                                                <h2 className="planCardheaderOrange">
                                                    Cosa è incluso:
                                                </h2>
                                                <div className="PlanCard">
                                                    <div className="PlanCardcomponent">
                                                        <SendIcon />
                                                        <h3>
                                                            15 interessi al
                                                            giorno
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <RemoveRedEyeIcon />
                                                        <h3>
                                                            vedi a chi interessi
                                                        </h3>
                                                    </div>
                                                    <div className="PlanCardcomponent">
                                                        <ReplyIcon />
                                                        <h3>torna indietro</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="InterrompiFilter">
                                                <a
                                                    onClick={() =>
                                                        setOpenPricing(true)
                                                    }
                                                >
                                                    Interrompi Abbonamento
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </TabContent>

                                <TabContent
                                    id="Impostazioni di ricerca"
                                    activeTab={activeTab}
                                >
                                    <div className="ricercatab">
                                        {/* <p>
                                            Facci sapere se vuoi essere visto
                                            dalle aziende
                                        </p>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked':
                                                            {
                                                                color: '#FFFFFF',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        'rgba(255, 255, 255, 0.08)',
                                                                },
                                                            },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                            {
                                                                backgroundColor:
                                                                    '#F06031',
                                                            },
                                                        '& .MuiSwitch-switchBase':
                                                            {
                                                                color: '#FFFFFF',
                                                            },
                                                        '& .MuiSwitch-track': {
                                                            backgroundColor:
                                                                '#EEEEEE',
                                                        },
                                                        '& .MuiSwitch-thumb': {
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Sono disponibile!"
                                            sx={{
                                                justifyContent: 'space-between',
                                                '.MuiFormControlLabel-label': {
                                                    color: '#333333',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        />
                                        <p className="mt1 ricerca">
                                            Aiutaci nella ricerca del lavoro
                                            perfetto per te
                                        </p> */}
                                        <p>
                                            Aiutaci nella ricerca del candidato
                                            perfetto per te
                                        </p>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked':
                                                            {
                                                                color: '#FFFFFF',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        'rgba(255, 255, 255, 0.08)',
                                                                },
                                                            },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                                                            {
                                                                backgroundColor:
                                                                    '#F06031',
                                                            },
                                                        '& .MuiSwitch-switchBase':
                                                            {
                                                                color: '#FFFFFF',
                                                            },
                                                        '& .MuiSwitch-track': {
                                                            backgroundColor:
                                                                '#EEEEEE',
                                                        },
                                                        '& .MuiSwitch-thumb': {
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                    checked={isRandom}
                                                    onChange={
                                                        toggleRandomChecked
                                                    }
                                                />
                                            }
                                            label={
                                                <div className="popover">
                                                    {isRandom
                                                        ? 'Modalità Kasuale attiva'
                                                        : 'Modalità Kasuale non attiva'}
                                                    <Tooltip
                                                        title="Attivando la modalità Kasuale vedrai qualsiasi candidato indipendentemente dalle caratteristiche della tua offerta e dai filtri attivi"
                                                        placement="bottom-end"
                                                        onClose={
                                                            handleTooltipClose
                                                        }
                                                        open={openTooltip}
                                                    >
                                                        <button
                                                            onClick={
                                                                handleTooltipOpen
                                                            }
                                                        >
                                                            i
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            }
                                            sx={{
                                                justifyContent: 'space-between',
                                                '.MuiFormControlLabel-label': {
                                                    color: '#333333',
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        />
                                        <div className="dialog-layout F0">
                                            <div>
                                                <h3>Distanza in Km</h3>
                                                <div
                                                    className="dialog-layout"
                                                    style={{
                                                        gridTemplateColumns:
                                                            '1fr ',
                                                    }}
                                                >
                                                    {/* <Box sx={{ width: 300 }}>
                                                        <Slider
                                                            aria-label="Always visible"
                                                            defaultValue={80}
                                                            // getAriaValueText={
                                                            //     valuetext
                                                            // }
                                                            step={10}
                                                            // marks={marks}
                                                            valueLabelDisplay="on"
                                                        />
                                                    </Box> */}
                                                    <Slider
                                                        onChange={
                                                            handleChangeSlider
                                                        }
                                                        // value={distance_filter}
                                                        defaultValue={
                                                            distance_filter
                                                        }
                                                        // disabled={isRandom}
                                                        // getAriaValueText={valuetext}
                                                        aria-labelledby="continuous-slider"
                                                        // aria-labelledby="continuous-slider"
                                                        valueLabelDisplay="on"
                                                        step={1}
                                                        min={2}
                                                        max={200}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="dialog-layout F0">
                                                <div className="d-flex flex-column space-between">
                                                    <FormLabel component="h3">
                                                        Tipologia lavoro
                                                    </FormLabel>
                                                    <FormControl
                                                        variant="filled"
                                                        fullWidth
                                                    >
                                                        <InputLabel
                                                            htmlFor="filled-age-native-simple"
                                                            id="demo-mutiple-checkbox-label"
                                                        >
                                                            Tipologia lavoro
                                                        </InputLabel>
                                                    </FormControl>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </TabContent>
                                <TabContent
                                    id="Account e privacy"
                                    activeTab={activeTab}
                                >
                                    <div>
                                        {' '}
                                        <FooterProfile />
                                    </div>
                                </TabContent>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default FilterPageRecruiter
