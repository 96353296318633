import React, { useEffect, useRef, useState } from 'react'
import ApiService from 'app/ApiService'
import useAuth from 'app/hooks/useAuth'
import useFirestore from 'app/hooks/useFirestore'
import ComebackIcon from '../Icons/ComebackIcon'
import LikeIcon from '../Icons/LikeIcon'
import DislikeIcon from '../Icons/DislikeIcon'
import ShowMoreIcon from '../Icons/ShowMoreIcon'
import HomeWorkIcon from '../Icons/HomeWorkIcon'
import LocationOn from '../Icons/LocationOn'
import getDistanceBetweenPoints from 'app/functions/ComputeDistance'
// import MatchAnimation from '../Animation/MatchAnimation'
import LoadingAnimation from '../Animation/LoadingAnimation'
import SwiperContainer, { SwiperSlide } from '../SwiperCard/SwiperContainer'
import { CompleteFormOffer } from './CompleteForm/CompleteFormOffer'
import history from 'history.js'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Closebtnblue from 'app/components/Icons/Closebtnblue'
import Typography from '@mui/material/Typography'
import ItsMatch from './ProfileComponents/Match/ItsMach'
import PricingPage from './Pricing/ComingSoonPremPopUp'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useHistory } from 'react-router-dom'

function Jump() {
    const [hideCards, setHideCards] = useState(false)
    // const [alreadyMatched, setAlreadyMatched] = useState(false)
    const [offers, setOffers] = useState([])
    // used for outOfFrame closure
    const [open, setOpen] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [guid, setGuid] = useState('')
    const [isPending, setIsPending] = useState(true)
    const [showPricingPage, setShowPricingPage] = useState(false)
    const [sub, setSub] = useState(null)
    const swiperContainerRef = useRef(null)
    const [userInfo, setUserInfo] = useState({
        geolocation: {},
        isAvailable: true,
    })
    if (process.env.NODE_ENV === 'production') {
        console.log('Running in production mode')
    } else {
        console.log('Running in development mode')
    }
    const { geolocation, isAvailable } = userInfo
    const history = useHistory()

    const { user } = useAuth()
    const { createMatch, sendLike, getActiveSubscription } = useFirestore()
    // const [longPressTimer, setLongPressTimer] = useState(null)

    const computeOffers = async () => {
        try {
            const response = await ApiService.find_offers(user.uid)
            const body = await response.json()
            console.log(body)

            setOffers(body)

            setIsPending(false)
        } catch (err) {
            console.error(err.message)
            history.push('/404')
        }
    }

    const getUserInfo = async () => {
        try {
            const response = await ApiService.get_candidatebyId(user?.uid)
            const body = await response.json()
            console.log(body)

            setUserInfo({
                ...userInfo,
                geolocation: body.geolocation,
                isAvailable: body.isAvailable,
            })

            const res = await getActiveSubscription(user.uid)
            setSub(res)
        } catch (err) {
            console.error(err.message)
        }
    }

    const swiped = async (direction, swiped, swiperInstance) => {
        // debugger
        // return
        // console.log('direction ', direction)
        // console.log('swiped ', swiped)
        const res = await ApiService.update_candswipe({
            offer_guid: offers[swiped.previousIndex][0],
            candidate_guid: user.uid,
            direction: direction,
        })

        if (res.ok) {
            const body = await res.json()

            if (body?.entity?.match && body?.entity?.allow) {
                // Match found
                createMatch(
                    offers[swiped.previousIndex][0],
                    user.uid,
                    offers[swiped.previousIndex][7],
                    offers[swiped.previousIndex][3],
                    true
                )

                setHideCards(true)
                //setTimeout(() => {
                //  setHideCards(false)
                //}, 6000)
            } else if (direction && body?.entity?.allow) {
                sendLike(
                    user.uid,
                    offers[swiped.previousIndex][7],
                    offers[swiped.previousIndex][3],
                    offers[swiped.previousIndex][0]
                )
            } else if (!body?.entity?.allow) {
                swiperInstance.tinder.reset()
                setShowPricingPage(true)
            }
        }

        let feedback = parseInt(localStorage.getItem('feedback'), 10) || 0
        feedback += 1
        if (feedback === 5 || feedback === 15) {
            setOpenPopup(true)
        }
        localStorage.setItem('feedback', feedback)
    }

    const handleBackClick = () => {
        if (!sub || sub.status !== 'active') {
            setShowPricingPage(true)
        }
    }

    const handleSwiping = (direction) => {
        if (swiperContainerRef.current?.swiperInstance) {
            if (direction)
                swiperContainerRef.current.swiperInstance.tinder.yes()
            else swiperContainerRef.current.swiperInstance.tinder.no()
        }
    }

    async function getInfo() {
        await getUserInfo()
        await computeOffers()
    }
    useEffect(() => {
        if (user?.uid) {
            getInfo()
        }
    }, [user])

    const disallowSwipe = () => {
        try {
            if (!sub || sub.status !== 'active') {
                setShowPricingPage(true)
                return false
            } else {
                return true
            }
        } catch (err) {
            console.error(err.message)
            return false
        }
    }

    // useEffect(() => {
    //     setShowPricingPage(true)
    // }, [])

    return (
        <div className={hideCards ? 'AnimationContainer' : 'container jump'}>
            {showPricingPage && (
                <PricingPage
                    open={showPricingPage}
                    setOpen={setShowPricingPage}
                />
            )}

            {!hideCards && (
                <div className="Content-scroll-sticky jump">
                    <main className="root-swipe">
                        <div
                            className="app-swipe d-flex flex-column justify-center align-center"
                            id="jump"
                        >
                            <div className="p-relative p-up-down d-flex flex-column justify-center align-center">
                                {isPending ? (
                                    // <div className="loading">
                                    //     <span className="loader"></span>
                                    // </div>
                                    <LoadingAnimation />
                                ) : offers && offers?.length > 0 ? (
                                    <SwiperContainer
                                        ref={swiperContainerRef}
                                        onTinderSwipe={swiped}
                                        handleBack={() => disallowSwipe()}
                                    >
                                        {/* <div className="card-container"> */}
                                        {offers
                                            .sort((a, b) =>
                                                a[1] <= b[1] ? 1 : -1
                                            )
                                            .map((character, index) => {
                                                return (
                                                    <SwiperSlide
                                                        key={index}
                                                        style={{
                                                            zIndex: 999,

                                                            borderRadius:
                                                                '3.125rem',
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setGuid(
                                                                character[0]
                                                            )
                                                            setOpen(true)
                                                        }}
                                                    >
                                                        <article
                                                            className={
                                                                hideCards
                                                                    ? 'job-card-ad hide'
                                                                    : 'job-card-ad'
                                                            }
                                                            // style={{
                                                            //     backgroundImage: `url(${
                                                            //         character[6] &&
                                                            //         character[6] !==
                                                            //             'None'
                                                            //             ? character[6]
                                                            //             : '/images/prof.png'
                                                            //     })`,
                                                            //     backgroundSize:
                                                            //         'cover',
                                                            //     backgroundPosition:
                                                            //         'center',
                                                            // }}
                                                        >
                                                            <div className="swiper-tinder-label swiper-tinder-label-yes">
                                                                <Typography
                                                                    variant="h4"
                                                                    component="span"
                                                                    style={{
                                                                        color: '#ea6c3a',
                                                                        fontWeight:
                                                                            'bold',
                                                                        textShadow: `
                                                                        -1px -1px 0px #fff,  
                                                                        1px -1px 0px #fff,  
                                                                        -1px 1px 0px #fff,  
                                                                        1px 1px 0px #fff`,
                                                                        display:
                                                                            'inline-block',
                                                                        padding:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    MI PIACE
                                                                </Typography>
                                                            </div>
                                                            <div className="swiper-tinder-label swiper-tinder-label-no">
                                                                <Typography
                                                                    variant="h4"
                                                                    component="span"
                                                                    style={{
                                                                        color: '#8773fc',
                                                                        fontWeight:
                                                                            'bold',
                                                                        textShadow: `
                                                                        -1px -1px 0px #fff,  
                                                                        1px -1px 0px #fff, 
                                                                        -1px 1px 0px #fff,  
                                                                        1px 1px 0px #fff`,
                                                                        display:
                                                                            'inline-block',
                                                                        padding:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    NON FA PER
                                                                    ME
                                                                </Typography>
                                                            </div>
                                                            <div className="d-flex align-center">
                                                                {/* <img src={character[6]} alt="" /> */}
                                                                <img
                                                                    src={
                                                                        character[6] !==
                                                                            null &&
                                                                        character[6] !==
                                                                            '' &&
                                                                        character[6] !==
                                                                            'None'
                                                                            ? character[6]
                                                                            : '/images/prof.png'
                                                                    }
                                                                    alt="Immagine profilo"
                                                                />
                                                                <h3 className="ml1 text-ellipsis-1">
                                                                    {' '}
                                                                    {
                                                                        character[2]
                                                                    }
                                                                </h3>
                                                            </div>{' '}
                                                            <h2 className="text-ellipsis-3">
                                                                {character[3]}
                                                            </h2>
                                                            {/* <h3 className="text-ellipsis-1">
                                                                    {' '}
                                                                    {
                                                                        character[2]
                                                                    }
                                                                </h3> */}
                                                            {/* <p>RAL: {character[5]} €</p> */}
                                                            <div className="">
                                                                <p
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <LocationOn
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    />{' '}
                                                                    {getDistanceBetweenPoints(
                                                                        geolocation?.lat,
                                                                        geolocation?.lon,
                                                                        character[8],
                                                                        character[9]
                                                                    ) > 2
                                                                        ? getDistanceBetweenPoints(
                                                                              geolocation?.lat,
                                                                              geolocation?.lon,
                                                                              character[8],
                                                                              character[9]
                                                                          )
                                                                        : '< 5'}{' '}
                                                                    km
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <HomeWorkIcon
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    />{' '}
                                                                    {
                                                                        character[4]
                                                                    }
                                                                </p>
                                                            </div>
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault()
                                                                    setGuid(
                                                                        character[0]
                                                                    )
                                                                    setOpen(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                VEDI L'OFFERTA
                                                                COMPLETA &nbsp;
                                                                <ShowMoreIcon />
                                                            </button>
                                                            <div className="frame-shape"></div>
                                                        </article>
                                                    </SwiperSlide>
                                                )
                                            })}

                                        <SwiperSlide
                                            style={{
                                                zIndex: 999,
                                                boxShadow:
                                                    '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '3.125rem',
                                            }}
                                        >
                                            <div
                                                className={
                                                    'swiper-tinder no-cards'
                                                }
                                            >
                                                <div
                                                    className="card-container"
                                                    id="placeholdercards"
                                                >
                                                    {/* <div className="bg-el"></div> */}
                                                    <SearchOutlinedIcon
                                                        style={{
                                                            width: '2em',
                                                            height: '2em',
                                                        }}
                                                    />
                                                    <h3
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        {' '}
                                                        OPS!
                                                        <span>
                                                            Sembra che non ci
                                                            siano più offerte
                                                        </span>
                                                    </h3>
                                                    <p
                                                        style={{
                                                            marginBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        Modifica i filtri,
                                                        attiva la modalità
                                                        Kasuale o torna più
                                                        tardi.
                                                    </p>
                                                    <button
                                                        className="buttonJump"
                                                        onClick={() =>
                                                            history.push(
                                                                '/settings'
                                                            )
                                                        }
                                                    >
                                                        Vai alle impostazioni
                                                    </button>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <div
                                            className={
                                                hideCards
                                                    ? 'job-card-ad hide'
                                                    : 'actions d-flex space-between align-center'
                                            }
                                        >
                                            <div className="icons-jump swiper-tinder-button-no">
                                                <button className="swiper-tinder-button">
                                                    <DislikeIcon />
                                                </button>
                                                <p>Non fa per me</p>
                                            </div>
                                            <div
                                                className="icons-jump swiper-tinder-button-undo"
                                                style={{
                                                    position: 'relative',
                                                    left: '-0.4rem',
                                                }}
                                            >
                                                <button
                                                    className="swiper-tinder-button"
                                                    onClick={handleBackClick}
                                                >
                                                    <ComebackIcon />
                                                </button>
                                                <p>Indietro</p>
                                            </div>
                                            <div className="icons-jump swiper-tinder-button-yes">
                                                <button className="swiper-tinder-button">
                                                    {/* <i className="icon-like"></i> */}
                                                    <LikeIcon />
                                                </button>
                                                <p>Candidati</p>
                                            </div>
                                        </div>
                                    </SwiperContainer>
                                ) : (
                                    <SwiperSlide
                                        style={{
                                            zIndex: 999,
                                            boxShadow:
                                                '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '3.125rem',
                                            width: '80%',
                                            margin: 'auto',
                                            top: '0rem',
                                        }}
                                    >
                                        <div
                                            className={'swiper-tinder no-cards'}
                                        >
                                            <div
                                                className="card-container"
                                                id="placeholdercards"
                                            >
                                                <div className="bg-el"></div>
                                                <SearchOutlinedIcon
                                                    style={{
                                                        width: '2em',
                                                        height: '2em',
                                                    }}
                                                />
                                                {isAvailable ? (
                                                    <>
                                                        <h3
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            {' '}
                                                            OPS!
                                                            <span>
                                                                Sembra che non
                                                                ci siano più
                                                                offerte
                                                            </span>
                                                        </h3>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Modifica i filtri,
                                                            attiva la modalità
                                                            Kasuale o torna più
                                                            tardi.
                                                        </p>{' '}
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            {' '}
                                                            OPS!
                                                            <span>
                                                                Sembra che il
                                                                tuo profilo non
                                                                sia attivo
                                                            </span>
                                                        </h3>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Attiva la funzione
                                                            'sono disponibile'
                                                            per vedere nuove
                                                            offerte di lavoro
                                                        </p>{' '}
                                                    </>
                                                )}
                                                <button
                                                    className="buttonJump"
                                                    onClick={() =>
                                                        history.push(
                                                            '/settings'
                                                        )
                                                    }
                                                >
                                                    Vai alle impostazioni
                                                </button>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </div>
                        </div>
                        <CompleteFormOffer
                            open={open}
                            setOpen={setOpen}
                            guid={guid}
                            actions={true}
                            swiped={handleSwiping}
                        />
                    </main>
                </div>
            )}
            <div>
                <Dialog
                    open={openPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="confirm-dialog small-dialog"
                >
                    <Button
                        onClick={() => setOpenPopup(false)}
                        color="primary"
                        className="close-dialog"
                        id="swipCloseBtn"
                    >
                        <Closebtnblue />
                    </Button>
                    <DialogTitle className="Dicci">
                        {'Dicci la tua!'}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Dacci il tuo parere per migliorare la piattaforma.
                            Valuta la tua esperienza cliccando il pulsante qui
                            sotto. Grazie!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenPopup(false)
                                window.location.href =
                                    'https://hylkxrsnso4.typeform.com/to/PFyXdexF'
                            }}
                            color="primary"
                            autoFocus
                        >
                            Prosegui
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {hideCards && (
                <ItsMatch
                    imageSrc={
                        offers &&
                        offers.length > 0 &&
                        offers[0][6] !== null &&
                        offers[0][6] !== '' &&
                        offers[0][6] !== 'None'
                            ? offers[0][6]
                            : '/images/prof.png'
                    }
                    setOpen={setHideCards}
                />
            )}
        </div>
    )
}

{
    /* <div className={'swiper-tinder no-cards'}>
                                        <div
                                            className="card-container"
                                            id="placeholdercards"
                                        >
                                            <div className="placeholder-card h-100">
                                                <div className="bg-el"></div>
                                                <LocationOnIcon
                                                    style={{
                                                        width: '2em',
                                                        height: '2em',
                                                    }}
                                                />
                                                <h3
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    {' '}
                                                    OPS!
                                                    <span>
                                                        Sembra che tu no sia
                                                        geolocalizzato/a!
                                                    </span>
                                                </h3>
                                                <p>
                                                    Attiva la geolocalizzazione
                                                    e inizia a trovare match
                                                </p>
                                                <button className="buttonJump">
                                                    torna ai match
                                                </button>
                                            </div>
                                        </div>
                                    </div> */
}

export default Jump
